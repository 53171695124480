import React from 'react';
import ReactGA from '../../utils/google-analytics';
import AuthWrapper from '../../components/layout/AuthWrapper';
import Head from '../../components/layout/Head';
import { LayoutBlank } from '../../components/layout/Layout';
import FillForm from '../../components/organisms/Form/FillForm';

const isBrowser = typeof window !== 'undefined';

const LearnerFormPage = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <AuthWrapper userRole="admin">
      <Head title="Learner Form" />
      <LayoutBlank>
        <FillForm userType="trainingProvider" />
      </LayoutBlank>
    </AuthWrapper>
  );
};

export default LearnerFormPage;
